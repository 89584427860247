export default {
  items: [
    {
      name: "Prime Dashboard",
      url: '/admin/dash',
      icon: 'icon-speedometer'
    },
    {
      name: "Performance Dashboard",
      url: 'https://lookerstudio.google.com/u/0/reporting/9661ff92-aa85-44c9-abe2-689e48a4c6ab/page/p_34gcrjchpd',
    },
    {
      name: "Operations",
      icon: 'icon-book-open',
      url: '/ops',
      children: [
        {
          name: "Placements",
          url: "/man/placements"
        },
        {
          name: "Smartlinks V2",
          url: "/apps/smartlinks/campaignsV2"
        },
        {
          name: "SmartLinks Groups",
          url: "/apps/smartlinks/SlGroups"
        },
        {
          name: "SL Offer Search",
          url: "/apps/smartlinks/offerSearch"
        },
        {
          name: "Global Offers Scraper",
          url: "/apps/smartlinks/home-global-offers-scraper"
        },
        {
          name: "PID Lists",
          url: "/man/pid-list"
        },
        {
          name: "PID Pool",
          url: "/man/pid-pool"
        },
        {
          name: "Bundle Pool",
          url: "/man/bundle-pool"
        },
        {
          name: "AF Traffic Monitor",
          url: "/man/af-data"
        },
        {
          name: 'Device ID BL',
          url: '/apps/smartlinks/blacklisting'
        }
      ] 
    },
    {
      name: "Media Analysts",
      icon: 'icon-book-open',
      url: '/media',
      children: [
        {
          name: "Script Tags",
          url: "/apps/script-tags/h"
        },
        {
          name: "Tag Generator",
          url: "/apps/script-tags/tag-generator"
        },
        {
          name: "Domains",
          url: "/dom/domainsList"
        },
        // {
        //   name: "New Domains Management",
        //   url: "/dom/domains-view"
        // },
        {
          name: "Banners",
          url: "/man/banners/active"
        },
        {
          name: " Tag Monthly Report",
          url: "/apps/script-tags/stags-req-report"
        },
        {
          name: "DSP Schedule",
          url: "/media-analyst/dsp-schedule"
        },
      ]
    },
    {
      name: "CSM",
      url: '/csm',
      icon: 'icon-book-open',
      children: [
        {
          name: "Appsflyer Link Generator",
          url: "/man/appsflyer-link-generator"
        },
        {
          name: "Adjust Link Generator",
          url: "/man/adjust-link-generator"
        },
        {
          name: "Manual Postback",
          url: "/man/manual-postback"
        },
        {
          name: "Bundle IDs",
          url: "/bundle-ids/home"
        },
        {
          name: "Advertisers",
          url: "/apps/script-tags/advertisers-manager"
        },
        {
          name: "Global Offers",
          url: "/apps/smartlinks/global-offers-v2"
        }, 
        {
          name: "Update Manual Revenue",
          url: "/man/update-manual-revenue"
        }, 
      ]
    },
    {
      name: "Admin Manage",
      url: '/adminman',
      icon: 'icon-book-open',
      children: [
        {
          name: 'Data Manager',
          url: '/apps/script-tags/data-manager'
        },
        {
          name: 'Users Management',
          url: '/admin/edit-user'
        },
        {
          name: 'Roles Management',
          url: '/admin/edit-roles'
        },
        {
          name: 'Permissions Management',
          url: '/admin/edit-permissions'
        },
        {
          name: 'BETA - Data Dashboard',
          url: '/admin/dash'
        },
      ]
    },
    {
      name: "Misc",
      url: '/misc',
      icon: 'icon-book-open',
      children: [
        {
          name: "Smartlinks",
          url: "/apps/smartlinks/campaigns"
        },
        {
          name: "SmartLinks VTA",
          url: "/apps/smartlinksVTA/campaigns"
        },
        {
          name: "Deleted Banners",
          url: "/man/banners/deleted"
        },
        {
          name: "Campaigns",
          url: "/man/performance-overview"
        },
        {
          name: "Adv Dashboard",
          url: "https://sys01.apptimusmedia.com/"
        },
        {
          name: "Apptimus DSP",
          url: "https://dsp.apptimus.media"
        },
        {
          name: "Insights",
          url: "/insights"
        },
      ]
    },
  ]
}